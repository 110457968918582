import { Environment } from "./environment-type";

export const environment: Environment = {
	production: false,
	instance: 'US',
	googleApiKey: 'AIzaSyCfg8LONCPd5oaeOquvWFuzaf9OOGWD5JU',
	webApiUrl: 'https://web-api.feature-us.taradel.com',
	creditKeyPublicKey: 'taradel_316ac48f39a645a48d98542d41057fbd',
	creditKeyEnvironment: 'staging',
	identity: {
		authority: 'https://identity.dev-us.taradel.com',
		responseType: 'id_token token',
		scope: 'openid profile taradel-web-api customer.profile offline_access',
	}
};
